@import './colors';
@import './mixins/generic-get';

@function get-color($namespace: primary, $variance: main) {
  $color-map: get($color, $namespace);
  @return get($color-map, $variance);
}

@function get-time($key: normal) {
  @return get($time, $key);
}

@function get-font-weight($key: medium) {
  @return get($font-weight, $key);
}

@function get-font-size($key: m) {
  @return get($font-size, $key);
}

@function get-line-height($key: m) {
  @return get($line-height, $key);
}

@function get-border-radius($key: xs) {
  @return get($border-radius, $key);
}

@function get-spacing($key: m) {
  @return get($spacing, $key);
}

@function get-media($key: m) {
  @return get($media, $key);
}

@function get-z($key: m) {
  @return get($z, $key);
}

@function get-box-shadow() {
  @return 0px 8px 16px rgba(0, 0, 0, 0.12);
}

$time: ( slow: 0.5s, normal: 0.35s, fast: 0.15s );

$font-weight: ( regular: 400, medium: 500, bold: 700 );

$line-height: (
// xs: 0.75rem,
s: 1, m: 1.1875, l: 1.25,
// l: 1.3125rem,
// xl: 1.5rem
);

$font-size: ( xxs: 0.75rem, xs: 0.875rem, s: 1rem, m: 1.125rem, l: 1.25rem, xl: 1.75rem );

//$border-radius: ( xs: 4px, s: 6px,
//// m: 9px,
//// l: 1.25rem,
//// xl: 1.5rem
//);
$border-radius: 4px;
$padding: 12px;

$spacing: ( xxs: 0.375rem, xs: 0.75rem, s: 1rem, sm: 1.25rem, m: 1.5rem, l: 2rem, xl: 2.5rem );

//$media: ( xs: 640px, s: 768px, m: 1024px, lg: 1280px, xl: 1440px, xxl: 1920px, xxxl: 2560px, 4k: 3840px );
//
//:export {
//  @each $key, $value in $media {
//    media-#{$key}: $value;
//  }
//}

$z: (
// xs: 1,
s: 10, m: 20,
// l: 30,
xl: 1300, xxl: 1400 );

$link-color: auto;
$link-hover-color: auto;
$link-hover-decoration: none;

$hover-color: rgba(0, 0, 0, 0.04);

$box-shadow: rgba(0, 0, 0, 0.1) 0px .5px 1px 0px, rgba(0, 0, 0, 0.06) 0px .1px .1px 0px;

$table-second-color: #ECF1F8;

$table-header-color: #014A7D;

$banner-height: 0px;
$ribbon-height: 64px;
$background-color: #F5F6F7;
$primary-background-color: #183C59;
$primary-foreground-color: #FFFFFF;
$main-border: 1px #B3B9C488 solid;
