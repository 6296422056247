@import "./base/variables";

.ribbon {
  height: $ribbon-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 60px 0px 50px;


  @media only screen and (min-width: 600px) {
    padding: 0px 60px 0px 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0px 36px 0px 36px;
  }

  .ribbon-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  .ribbon-block-right {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .ribbon-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 4px;
  }

  .logo {
    height: 48px;
  }

  .name {
    font-size: 32px;
    font-weight: bold;
    font-family: 'Oleo Script';
  }
}

.headline {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 24px;
}

.content {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 24px;
}

.tags {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  background-color: #ebebeb;
  text-transform: uppercase;
}

.keepintouchsection {
  //height: calc(-#{$ribbon-height} - #{$banner-height} + 100vh);
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 56px 48px 56px 48px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    padding: 0px 24px 48px 24px;
    gap: 0px;
  }

  .half-block-left {
    flex: 1;
    max-width: 500px;
  }

  .half-block-right {
    flex: 1;
    max-width: 500px;

    @media only screen and (max-width: 600px) {
      max-width: none;
      flex: auto;
    }
  }

  .headimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: $border-radius;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}

.section1 {
  //height: calc(-#{$ribbon-height} - #{$banner-height} + 100vh);
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 56px 48px 56px 48px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    padding: 0px 24px 48px 24px;
    gap: 0px;
  }

  .half-block-left {
    flex: 1;
    max-width: 500px;

    @media only screen and (max-width: 600px) {
      max-width: none;
      flex: auto;
    }
  }

  .half-block-right {
    flex: 1;
    max-width: 500px;
  }

  .headimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: $border-radius;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}

.section2 {
  align-self: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding: 56px 48px 56px 48px;
  max-width: 1000px;

  @media only screen and (max-width: 600px) {
    padding: 0px 24px 48px 24px;
    gap: 0px;
  }

  .title {
    text-align: center;
  }

  .subtitle {
    font-weight: 600;
    font-size: 18px;
    padding: 24px 0px;
  }

  .subcontent {
    display: flex;
    flex-direction: row;
    gap: 24px;
    //padding: 56px 48px 56px 48px;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .valueimg {
    height: 70px;
    width: auto;
  }

  .valuetitle {
    font-weight: bold;
    padding-bottom: 8px;
  }

  .separator {
    width: 300px;
    border-bottom: #091E4220 solid 1px;
    margin: 36px 0px;
  }

  .half-block {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 500px;
    gap: 24px;
    align-items: center;
  }
}

.section3 {
  align-self: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding: 56px 48px 56px 48px;
  max-width: 1000px;

  @media only screen and (max-width: 600px) {
    padding: 0px 24px 48px 24px;
    gap: 0px;
  }

  .title {
    text-align: center;
  }

  .subtitle {
    font-weight: 600;
    font-size: 18px;
    padding: 36px 0px 12px 0px;
    margin: 0px;
  }

  .content {
    font-weight: 400;
    font-size: 16px;
    padding: 12px 0px 0px 0px;
    margin: 0px;
  }

  .subcontent {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    padding: 0px 0px 24px 0px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 0px;
    }
  }

  .half-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    align-self: flex-start;
  }

  .half-block-left {
    border-right: #091E4220 solid 1px;

    @media only screen and (max-width: 600px) {
      border-right: none;

    }
  }
}

.section4 {
  align-self: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding: 56px 48px 56px 48px;
  max-width: 1000px;

  @media only screen and (max-width: 600px) {
    padding: 24px 24px 48px 24px;
    gap: 0px;
  }

  .carousel {
    width: auto;
  }

  .carousel_card {
    width: 250px;
    height: 100px;
    background-color: red;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.section5 {
  align-self: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding: 56px 48px 56px 48px;
  max-width: 1000px;

  @media only screen and (max-width: 600px) {
    padding: 0px 24px 48px 24px;
    gap: 0px;
  }

  .title {
    text-align: center;
    padding: 0px 0px 36px 0px;
  }

  .promo {
    display: flex;

    .promoTitle {
      font-weight: 600;
      font-size: 18px;
      padding: 0px 0px 12px 0px;
      margin: 0px;
    }

    .promoContent {
      font-weight: 400;
      font-size: 16px;
      padding: 12px 0px 0px 0px;
      margin: 0px;
    }
  }

  .card {
    background-color: #FFE7E7;
    height: 230px;
    border: #091E4233 solid 0.5px;
    border-radius: 8px;
    box-shadow: 0.5px 1.5px #091E4233;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
      width: calc(100% - 24px);
    }

    .cardtitle {
      font-weight: 600;
      font-size: 16px;
    }

    .cardneeds {
      font-weight: 400;
      font-size: 12px;
      padding: 12px 0px 0px 0px;
    }

    .cardpromoprice {
      font-weight: 600;
      font-size: 16px;
      padding: 12px 0px 0px 0px;
    }

    .cardprice {
      font-weight: 800;
      font-size: 24px;
      padding: 12px 0px 0px 0px;
    }

    .currency {
      font-weight: 500;
      font-size: 18px;
    }

    .barred {
      text-decoration: line-through;
    }

    .onboarding {
      font-weight: 400;
      font-size: 12px;
    }

    .onboardingfees {
      font-weight: 400;
      font-size: 14px;
      padding: 8px 0px 0px 0px;
    }

    .payment {
      padding: 8px 0px 0px 0px;
      color: #091E42AA;
      font-weight: 400;
      font-size: 12px;
    }
  }

  .pricing {
    padding: 24px 0px 36px 0px;
  }

  .promocard {
    background-color: #E7FFF7;
  }

  .featureseparator {
    border-bottom: #091E4233 solid 0.5px;
  }

  .feature {
    text-align: center;
  }

  .featuretitle {
    text-align: left;
    font-weight: 600;
  }

  .featureheader {
    font-weight: 600;
  }

  .inclusion {
    height: 35px;
    width: auto;
    margin-top: -8px;
    margin-bottom: -16px;
  }

  .fees {
    padding: 48px 0px 36px 0px;
  }

  .feesTitle {
    font-weight: 600;
    font-size: 18px;
    padding: 0px 0px 12px 0px;
    margin: 0px;
  }
}

.keepintouchform {
  border-radius: $border-radius;
  border: $primary-background-color solid 0.5px;
  background-color: white;
  padding: 36px;
  flex: 1;
  height: max-content;

  @media only screen and (min-width: 600px) {
    border-radius: $border-radius;
    border: $primary-background-color solid 0.5px;
  }

  .header {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 18px;
  }

  .headline {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 24px;
  }
}

.hidden {
  display: none;
}
