@import "../../../styles/base/variables";

.before-footer {
  height: 48px;
}

.footer {
  //height: 150px;
  background: $primary-background-color;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 12px 48px 12px;

  .quater-block-1 {
    //border: red solid 1px;
    //height: calc(30vh);
    flex: 2 1 auto;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .contact {
    color: white;
    font-weight: 500;
    font-size: 12px;
  }

  .email {
    color: white;
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;
  }

  .email:hover {
    text-decoration: underline;
  }

  .quater-block-2 {
    //border: red solid 1px;
    //height: calc(30vh);
    flex: 1 1 auto;
    max-width: 250px;
    display: flex;
    flex-direction: column;
  }

  .footer-header {
    font-weight: 600;
    font-size: 16px;
    color: white;
    margin-bottom: 24px;
  }

  .footer-link {
    font-weight: 400;
    font-size: 14px;
    color: white;
    margin-bottom: 12px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
