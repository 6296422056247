@import "../../styles/base/variables";

.app-frame {
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  border-bottom:  #183C5933 solid 0.5px;
}

.app-content {
  overflow-y: auto;
}

.app-bluebanner {
  background-color: $primary-background-color;
  height: $banner-height;
}
