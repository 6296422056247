.valuecard {
  //border: #183C59 solid 1px;
  height: calc(30vh);
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.vc-img {
  max-width: 100px;
  margin-bottom: 24px;
}

.vc-headline {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 24px;
}

.vc-content {
  font-weight: 400;
  font-size: 14px;
}
